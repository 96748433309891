<template>
  <div v-if="url">
    <v-app-bar app>
        <v-btn icon @click="submit">
            <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
    </v-app-bar>
    <v-row align="center" justify="center">
      <v-col align="center" justify="center">
        <loading-overlay :loading="loading" />
      </v-col>
    </v-row>
    <iframe
      :src="`${url}?random=${(new Date()).getTime() + Math.floor(Math.random() * 1000000)}`"
      id='iframeEl'
      @load='onLoad'
      @iframe-load='onIframeLoad'
      frameborder="0" >
    </iframe>
    <!-- <v-row align="center" justify="center" v-if="retry" id="bg">
        <v-col align="center" justify="center">
            <v-btn class="mr-4 mt-1 mb-1" @click="submit" color="blue" dark>
                RETRY PAYMENT
            </v-btn>
        </v-col>
    </v-row> -->
  </div>
</template>

<script>

import LoadingOverlay from '@/views/component/LoadingOverlay.vue';

export default {
  props: ["url", "retry"],
  components: {
    LoadingOverlay
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    onLoad() {
      console.log('iframe loaded: onload');
      document.getElementById('iframeEl').contentWindow.postMessage("message", '*')
      if (process.browser) {
        console.log("listen to message")
        window.addEventListener('message', message => {
          console.log(message)
          if(message && message.data) {
            try {
              const data = JSON.parse(message.data)
              if(data && data.url) {
                this.loading = true
                window.location.replace(data.url)
              }
            } catch(e) {}
          }
        })
      }
    },
    onIframeLoad() {
      console.log('iframe loadedL oniframeload');
    },
    submit() {
      const url = this.retry
      window.location.replace(url)
    }

  },
}
</script>

<style scoped>
body {
    margin: 0;            /* Reset default margin */
}
#iframeEl {
    display: block;       /* iframes are inline by default */
    background: #000;
    border: none;         /* Reset default border */
    height: 100vh;        /* Viewport-relative units */
    width: 100vw;
}
#bg {
  background: #ebebf0 !important;
}
</style>
