<template>
<div>
<v-progress-circular v-if="isLoading" indeterminate color="primary">
</v-progress-circular>
</div>
</template>

<script>
export default {
    name: 'LoadingOverlay',
    props: {
        loading: Boolean
    },
    watch: {
        loading(val) {
            this.isLoading = val
            console.log('loading value changed', val)
        },
        deep: true
    },
    data() {
        return {
            isLoading: this.loading ?? false
        }
    },
    mounted() {
        console.log('mounting loading overlay; show? ', this.loading)
        this.isLoading = this.loading
    }
};
</script>
